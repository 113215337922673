import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="56.854"
    height="56.922"
    viewBox="0 0 56.854 56.922">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-0.711"
        y1="-2.185"
        x2="0.829"
        y2="1.245"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#30b9ce" />
        <stop offset="0.41" stopColor="#1b8090" />
        <stop offset="0.81" stopColor="#094d58" />
        <stop offset="1" stopColor="#023943" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-0.118"
        y1="-0.695"
        x2="1.423"
        y2="2.167"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="0.275"
        y1="0.05"
        x2="0.721"
        y2="0.939"
        xlinkHref="#linear-gradient"
      />
    </defs>
    <g id="sygnet" transform="translate(0.043 0.022)">
      <path
        id="Path_809"
        dataname="Path 809"
        d="M36.81,42.42l-.29-.1a3.48,3.48,0,0,1-1.15-.74c3.33-.08,6.67-4,7.8-10.07l.1-.52,5.89,1.71a3.61,3.61,0,0,1,2.52,2.66,10.67,10.67,0,0,1,0,4h0a2.209,2.209,0,0,1-.08.37l-.15.62c-.9,3.8-3.63,6.08-6,5.21Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_810"
        dataname="Path 810"
        d="M28.81,32.78l-1.75-.56L20.26,30c-1.62-.53-2.83-3.11-3-6.19l1.44.42c.79.23,1.54-.85,1.67-2.44s-.43-3.11-1.24-3.32l-1.2-.31a7.51,7.51,0,0,1,.69-1.51c1.08-1.7,2.28-1.42,2.28-1.42l9.45,2.22a4.19,4.19,0,0,1,2.76,1.75l.21.44h0c.09.23.18.46.27.71l.15.51a6.72,6.72,0,0,0-3.65,2.7,16.25,16.25,0,0,0-2.89,7.56h0l1.75.56c1.37.43,2.78-.68,3.62-2.69h0c.47-.94,1-.82,1-.82C32.72,31.44,30.72,33.41,28.81,32.78Z"
        fill="url(#linear-gradient-2)"
      />
      <path
        id="Path_811"
        dataname="Path 811"
        d="M56.79,26.2A28.46,28.46,0,1,0,45,51.57l.25-.18-.3-.12h0l-.24-.11L44.22,51h0l-4.07-1.6-8.1-3.2h0l-.13-.06h-.17L31.58,46h0l-.13-.08h0c-1.72-1-3.06-3.1-3.82-5.73h0c-.11-.38-.21-.77-.29-1.17L20.79,36.9a9.76,9.76,0,0,1-2-1.1A12.29,12.29,0,0,1,17,34.18c-3.09-3.52-2.95-9.85-2.93-10.29a12.947,12.947,0,0,1,.09-1.38l-8.42-2.4a1.49,1.49,0,0,0,0,.79,1.13,1.13,0,0,0,.34.57,2.22,2.22,0,0,0,.63.25l.38.12c.32.09,2.07.62,6.21,1.91a18.43,18.43,0,0,0,0,2,17.6,17.6,0,0,0,.37,2.61l-.9-.31-.58-.19-4.1-1.39c-.18-.06-2.82-.85-3.55-1.69a3.36,3.36,0,0,1-.62-1,5.38,5.38,0,0,1-.46-1.46,11.75,11.75,0,0,1-.23-2.63c0-5.57,2.84-4.59,2.84-4.59l8.52,2.2c1.67-7.83,6.5-7.48,6.71-7.46a.9.9,0,0,1,.28,0l2,.39,11.11,2.25A10.58,10.58,0,0,1,42.42,19a15.93,15.93,0,0,1,1.08,3.78l.89.22,1.12.28,9.16,2.26h0a6.49,6.49,0,0,1,1.61.66l.53.33Z"
        fill="url(#linear-gradient-3)"
      />
    </g>
  </svg>
)
