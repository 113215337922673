import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="279"
    height="281"
    viewBox="0 0 279 281">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#30bad2" />
        <stop offset="0.42" stopColor="#1c7e90" />
        <stop offset="0.81" stopColor="#0b4c58" />
        <stop offset="1" stopColor="#043943" />
      </linearGradient>
    </defs>
    <g id="Group_29" dataname="Group 29" transform="translate(-805 -333)">
      <path
        id="triangleN"
        dataname="Polygon 1"
        d="M19.072,2.321a1,1,0,0,1,1.857,0L39.451,48.629A1,1,0,0,1,38.523,50H1.477a1,1,0,0,1-.928-1.371Z"
        transform="translate(923 333)"
        fill="url(#linear-gradient)"
      />
      <path
        id="triangleS"
        dataname="Polygon 5"
        d="M19.072,2.321a1,1,0,0,1,1.857,0L39.451,48.629A1,1,0,0,1,38.523,50H1.477a1,1,0,0,1-.928-1.371Z"
        transform="translate(964 614) rotate(180)"
        fill="url(#linear-gradient)"
      />
      <path
        id="triangleE"
        dataname="Polygon 6"
        d="M19.072,2.321a1,1,0,0,1,1.857,0L39.451,48.629A1,1,0,0,1,38.523,50H1.477a1,1,0,0,1-.928-1.371Z"
        transform="translate(1084 454) rotate(90)"
        fill="url(#linear-gradient)"
      />
      <path
        id="triangleW"
        dataname="Polygon 7"
        d="M19.072,2.321a1,1,0,0,1,1.857,0L39.451,48.629A1,1,0,0,1,38.523,50H1.477a1,1,0,0,1-.928-1.371Z"
        transform="translate(805 494) rotate(-90)"
        fill="url(#linear-gradient)"
      />
      <g
        id="Ellipse_31"
        dataname="Ellipse 31"
        transform="translate(855 386)"
        fill="none"
        stroke="#138494"
        strokeWidth="10">
        <ellipse cx="89.5" cy="89" rx="89.5" ry="89" stroke="none" />
        <ellipse cx="89.5" cy="89" rx="94.5" ry="94" fill="none" />
      </g>
    </g>
  </svg>
)
